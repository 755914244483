import './set-public-path'
import EventBusService from './emitter/EventBus'

// Logger
export { initSentry, makeSentryHub } from './logging/services/sentry'
export { default as Logger } from './logging/Logger'
export { default as loggerPlugin } from './logging/loggerPlugin'

// Events
export const eventBus = new EventBusService()
export { default as globalEventsList } from './emitter/eventsList'

// Validators
export { EMAIL_REGEX, validateEmailAddress } from './validators/email'

// FeatureToggles
export { getIsFeatureEnabledInLocalStorage } from './featureToggles/localStorageFeatureToggles'

// Third party integrations
export { createStore } from 'vuex-extensions'
