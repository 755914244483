import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { SENTRY_DSN, ENVIRONMENT, IS_PRODUCTION } from '../../constants'

function getRestaurantId() {
  return localStorage.getItem('je.pcweb.restaurant_id')
}

function singleSpaRouterInstrumentation() {
  const SINGLE_SPA_ROUTING_EVENT = 'single-spa:before-routing-event'
  let isPageLoadNavigation = true

  return function (
    startTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true
  ) {
    // prevent integration for iframe
    if (window.self !== window.top) return false
    window.addEventListener(
      SINGLE_SPA_ROUTING_EVENT,
      ({ detail: { appsByNewStatus, newUrl, navigationIsCanceled } }) => {
        const { pathname } = new URL(newUrl)
        const payload = {
          name: pathname || newUrl,
          tags: {
            'routing.instrumentation': 'single-spa-router',
          },
          data: {
            appsByNewStatus,
            navigationIsCanceled,
          },
        }

        if (startTransactionOnPageLoad && isPageLoadNavigation) {
          startTransaction({
            ...payload,
            op: 'pageload',
          })
        }

        if (startTransactionOnLocationChange && !isPageLoadNavigation) {
          startTransaction({
            ...payload,
            op: 'navigation',
          })
        }

        isPageLoadNavigation = false
      }
    )
  }
}

const DEFAULT_OPTIONS = {
  dsn: SENTRY_DSN,
  autoSessionTracking: false,
  tracesSampleRate: 0,
  maxBreadcrumbs: 50,
  normalizeDepth: 5,
  environment: ENVIRONMENT,
  trackComponents: true,
  beforeSend(event) {
    if (window.self !== window.top) return null
    return IS_PRODUCTION ? event : null
  },
}

let isSentryInitialized = false

const { Error, Info } = Sentry.Severity

export const LEVEL = { Error, Info }

export function initSentry(sentryOptions = DEFAULT_OPTIONS, router = null) {
  try {
    const routingInstrumentation = router
      ? Sentry.vueRouterInstrumentation(router)
      : singleSpaRouterInstrumentation()

    Sentry.init({
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation,
        }),
      ],
      ...sentryOptions,
    })
    isSentryInitialized = true
  } catch (error) {
    throw new Error(error)
  }
}

export function makeSentryHub({
  packageName = '',
  release = '0.0.1',
  sentryOptions = {},
  router = null,
}) {
  const { dsn } = sentryOptions
  const preparedSentryOptions = {
    ...DEFAULT_OPTIONS,
    release,
    ...sentryOptions,
  }

  if (dsn || !isSentryInitialized) {
    initSentry(preparedSentryOptions, router)
  }

  const client = new Sentry.BrowserClient(preparedSentryOptions)
  const hub = new Sentry.Hub(client)
  const restaurantId = getRestaurantId()

  hub.configureScope((scope) => {
    if (packageName) {
      scope.setTag('package', packageName)
    }
    scope.setTag('restaurantId', restaurantId)
  })

  return { client, hub }
}
