import { makeSentryHub, LEVEL } from './services/sentry'

export default class Logger {
  #sentryHub

  /**
   * @param  {string} [packageName=''] will be prepended to data for each Logger call
   * @param  {string} [release = '0.0.1'] will be prepended to data for each Logger call
   * @param  {Object} [sentryOptions={}] allows to use custom options for sentry
   * @param  {Object} [router] allows to capture Vue routing events in logger
   */
  constructor({
    packageName = '',
    release = '0.0.1',
    sentryOptions = {},
    router,
  } = {}) {
    const { hub } = makeSentryHub({
      packageName,
      release,
      sentryOptions,
      router,
    })
    this.#sentryHub = hub
  }

  /**
   * Log an error
   * @param  {object} error - The actual original exception
   * @param  {object|null} [payload={}] - Any additional properties you want to add to the logs
   */
  error(error = '', payload = null) {
    this.#sentryHub.withScope((scope) => {
      scope.setExtras(payload)
      scope.setLevel(LEVEL.Error)
      this.#sentryHub.captureException(error)
    })
  }

  /**
   * Log info
   * @param  {string} message - Describe what happened
   * @param  {object|null} [payload] - Any additional properties you want to add to the logs
   */
  info(message, payload = null) {
    this.#sentryHub.withScope((scope) => {
      scope.setExtras(payload)
      scope.setLevel(LEVEL.Info)
      this.#sentryHub.captureMessage(message)
    })
  }
}
